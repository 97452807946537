import axios from 'axios';

const host = 'https://catalog.products.vpsvc.com';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

export async function getProducts() {
  const url = `${host}/api/products?requestor=ui-tool`;

  try {
    const result = await axios.get(url, { headers });
    return result.data;
  } catch (error) {
    throw error;
  }
}

export const getProductModel = async (productKey) => {
  const url = `${host}/api/products/${productKey}?requestor=ui-tool&includeInformationalAttributes=false`;

  try {
    const result = await axios.get(url, { headers });
    return result.data;
  } catch (error) {
    throw error;
  }
};
