import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { getProductSpecs } from '../api/tss-api';
import { AppContext } from '../App';

const TssResult = (props) => {
  const {productOptionCombination, tssResponse, setTssResponse} = useContext(AppContext);

  const [loading, setLoading] = useState();

  useEffect(() => {
    if (productOptionCombination) {
      setTssResponse(null);
      setLoading(true);
      getProductSpecs(productOptionCombination.productKey, productOptionCombination.productOptions)
        .then(response => {
          setTssResponse(response);
          setLoading(false);
        })
        .catch(err => {
          console.error('Error calling TSS:', err);
          setLoading(false);
        });
    }
    else {
      setTssResponse(null);
    }
  }, [productOptionCombination, setTssResponse]);

  return (
    <div className={props.className}>
      {loading
        ? (
            <div className={"text-secondary"}>
              <h6 className={"text-secondary mt-4"}>
                Waiting for service to return the data
              </h6>
              <p>
                Remote service call in progress. Please wait...
              </p>
            </div>
          )
        : tssResponse
        ? (
            <>
              <h6 className={"mt-4"}>Response</h6>
              <ReactJson src={tssResponse} defaultValue={{}} collapsed={false} />
            </>
          )
          : (
            <div className={"text-secondary"}>
              <h6 className={"mt-4"}>
                Product-Options Combination not set or invalid
              </h6>
              <p>
                Please, select a product from the <label htmlFor={"selected_product"}><mark>Product List</mark></label> and valid <label><mark>Product Options</mark></label>.
              </p>
            </div>
          )}
    </div>
  );
};

export default TssResult;
