import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import { create3DRenderingUrl, createInstructionsUrl, createMcpRenderingUrl } from '../tools/url-helper';

const DocumentDetails = (props) => {
  const {documentUrl} = useContext(AppContext);

  const [udsDocumentUrl, setUdsDocumentUrl] = useState();
  const [mcpRenderingUrl, setMcpRenderingUrl] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [rendering3dUrl, setRendering3dUrl] = useState();

  const checkDocumentUrl = useCallback(() => {
    return !!documentUrl && validateUrl(documentUrl);
  }, [documentUrl]);

  useEffect(() => {
    if (!checkDocumentUrl()) return;

    const uds = createInstructionsUrl(documentUrl, []);
    setUdsDocumentUrl(uds);

    const mcp = createMcpRenderingUrl(uds);
    setMcpRenderingUrl(mcp);

    const rendering3dUrl = create3DRenderingUrl(uds);
    setRendering3dUrl(rendering3dUrl);

    setPreviewUrl(mcp);
  }, [documentUrl, checkDocumentUrl]);

  const validateUrl = (str) => {
    return (/(http(s?)):\/\//gi.test(str));
  };

  return (
    <div className={props.className}>
      {checkDocumentUrl()
        ? (
          <>
            <h6 className={"mt-4"}>Preview</h6>
            <img src={previewUrl} alt={""} />

            <h6 className={"mt-5"}>UDS Document URL</h6>
            <a className={"text-break"} href={udsDocumentUrl} target="_blank" rel="noopener noreferrer">
              {udsDocumentUrl}
            </a>
            <h6 className={"mt-5"}>MCP Rendering URL</h6>
            <a className={"text-break"} href={mcpRenderingUrl} target="_blank" rel="noopener noreferrer">
              {mcpRenderingUrl}
            </a>
            <h6 className={"mt-5"}>VERTEX Rendering URL</h6>
            <a className={"text-break"} href={rendering3dUrl} target="_blank" rel="noopener noreferrer">
              {rendering3dUrl}
            </a>
          </>
        )
        : (
          <div className={"text-secondary"}>
            <h6 className={"mt-4"}>
              Document URL not set or invalid
            </h6>
            <p>
              Please, set correct <label htmlFor={"document_url"}><mark>Document URL</mark></label>.
            </p>
          </div>
        )}
    </div>
  );
};

export default DocumentDetails;
