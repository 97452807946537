import axios from 'axios';

const host = 'https://targetspecifications.designtransformation.vpsvc.com';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Caller-Id': 'content-catalog-ui'
};

function createRequest(productKey, options) {
  var optionsList = '';
  for (var key in options) {
    var option = `optionSelections[${encodeURIComponent(key)}]=${encodeURIComponent(options[key])}&`;
    optionsList = optionsList + option;
  }

  optionsList = optionsList.substring(0, optionsList.length - 1);
  var url = `${host}/api/v3/productSpecifications/${productKey}?${optionsList}`;

  return url;
}

export async function getProductSpecs(productKey, options) {
  const url = createRequest(productKey, JSON.parse(options));
  try {
    const result = await axios.get(url, { headers });
    return result.data;
  } catch (error) {
    throw error;
  }
}
