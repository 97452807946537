const udsInstructionsPath = 'https://uds.documents.cimpress.io/v3/instructions:preview';
const mcpRenderingHostPath = 'https://rendering.documents.cimpress.io/v1/vp/preview?width=1000';
const vortex3DRenderingHostPath = 'https://tools.documents.cimpress.io/3d/index.html';

export const createInstructionsUrl = (documentUrl, slots) => {
  const encodedDocumentUrl = encodeURIComponent(documentUrl);

  const slotsPrefix = slots.length === 0 ? '' : `&surfaceOrdinals=${encodeURIComponent(slots.join(','))}`;
  const udsUrl = `${udsInstructionsPath}?documentUri=${encodedDocumentUrl}${slotsPrefix}`;

  return udsUrl;
};

export const createMcpRenderingUrl = (instructionsUrl) => {
  const encodedDocumentUrl = encodeURIComponent(instructionsUrl);

  const udsUrl = mcpRenderingHostPath + '&instructions_uri=' + encodedDocumentUrl;

  return udsUrl;
};

export const create3DRenderingUrl = (instructionsUrl) => {
  const encodedDocumentUrl = encodeURIComponent(instructionsUrl);

  const vortex3DUrl = vortex3DRenderingHostPath + '?renderingInstructions=' + encodedDocumentUrl;

  return vortex3DUrl;
};

export const createComboWikiLink = (template) => {
  if (!template) {
    return '';
  }

  const comboId = template.split('..', 2)[0].substr(1);

  return `https://corewiki.cimpress.net/w/index.php?issimpleformsform=true&title=Combo_details&combo_id=${comboId}`;
};
