import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Form, Row, Button, Badge } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.min.css';
import { getProductModel, getProducts } from '../api/product-api';
import { AppContext } from '../App';
import { getProductExamples } from '../tools/data-reader';

const ProductOptions = () => {
  const {setProductOptionCombination} = useContext(AppContext);

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productModel, setProductModel] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [examples, setExamples] = useState([]);

  // load list of products
  useEffect(() => {
    getProducts()
      .then(data => {
        setProducts(data.map(x => { return { "id": x.productKey, "name": x.name }}));
      })
      .catch(err => console.error('Error calling product service:', err));
  }, []);

  const productsLoaded = () => {
    return products.length > 0;
  };

  // load list of product options
  useEffect(() => {
    if (selectedProduct && selectedProduct.length === 1) {
      getProductModel(selectedProduct[0].id)
        .then(data => {
          setProductModel(data);
        })
        .catch(err => console.error('Error calling product service by product key:', err));
     }
     else {
        setProductModel(null);
     }

  }, [selectedProduct]);

  const productModelSet = () => {
    return !!productModel;
  };

  // update product-options
  const getProductOptions = useCallback((productModel) => {
    const { key, name } = productModel;
    const optionsForProduct = selectedOptions[key] || [];
    const options = optionsForProduct.map(o => [o.name, o.value]);

    return {
      productKey: key,
      productName: name,
      productOptions: JSON.stringify(Object.fromEntries(options))
    };
  }, [selectedOptions]);

  useEffect(() => {
    if (productModel) {
      setProductOptionCombination(getProductOptions(productModel));
    }
    else {
      setProductOptionCombination(null);
    }
  }, [productModel, selectedOptions, getProductOptions, setProductOptionCombination]);

  const checkSelectedValue = (selectedOptions, optionName) => {
    const optionsForProduct = selectedOptions[productModel.key] || [];
    const found = optionsForProduct.find(x => x.name === optionName);

    if (found) {
      return found.value;
    }

    return '';
  };

  const handleInputChanged = (optionName, selectedValue) => {
    if (!productModel) return;

    let valueUpdated = false;
    const optionsForProduct = selectedOptions[productModel.key] || [];
    let updatedSelectedOptions = optionsForProduct.map(item => {
      if (item.name === optionName) {
        item.value = selectedValue;
        valueUpdated = true;
      }

      return item;
    });

    if (!valueUpdated) {
      updatedSelectedOptions = [ ...updatedSelectedOptions, { name: optionName, value: selectedValue }]
    }

    const newSelectedOptions = { ...selectedOptions };
    newSelectedOptions[productModel.key] = updatedSelectedOptions;
    setSelectedOptions(newSelectedOptions);
  };

  // load examples
  useEffect(() => {
    getProductExamples().then(data => {
      setExamples(data);
    })
  }, []);

  const setExample = (example) => {
    const { key, values } = example;
    const newSelectedOptions = { ...selectedOptions };
    newSelectedOptions[key] = values.map(item => { return { ...item }});
    setSelectedOptions(newSelectedOptions);

    const found = products.find(x => x.id === key);
    setSelectedProduct([found]);
  };

  const handleExampleClick = (example) => {
    setExample(example);
  };

  return (
    <>
      <h4 className={"mt-5"}>Product-Options Combination</h4>

      {
          <>
            <Typeahead
              id={"product_list"}
              inputProps={{ id: "selected_product"}}
              isLoading={!productsLoaded()}
              selected={selectedProduct}
              onChange={e => {
                setSelectedProduct(e);
              }}
              options={products}
              labelKey={"name"}
              selectHintOnEnter={true}
              clearButton={true}
              filterBy={['name', 'id']}
              maxResults={20}
              renderMenuItemChildren={(option, props, index) =>
                <>
                  <Badge className={"typeahed-badge"}>{option.id}</Badge> {option.name}
                </>
              }
              />

            {examples.length > 0 &&
              <div className={"mt-2"}>
                {examples.map(example => <Button key={example.name} disabled={!productsLoaded()} className={"mr-1 mb-1"} variant={"inverse"} onClick={() => handleExampleClick(example)}>{example.name}</Button>)}
              </div>
            }

            {productModelSet() &&
            <>
              <h6 className={"mt-4"}>Product Options</h6>
              <Form>
                {productModel && productModel.options.list.map((option, optionIndex) => (
                  <Form.Group as={Row} key={"product-option-" + optionIndex}>
                    <Form.Label column sm={4}>
                      {option.name}
                    </Form.Label>
                    <Col sm={8}>
                      {option.values.length > 2
                        ? (
                          <Form.Control as={"select"} value={checkSelectedValue(selectedOptions, option.name)} onChange={e => handleInputChanged(option.name, e.target.value)}>
                            <option value={""}>[Not set]</option>
                            {option.values.map((x, i) => (
                              <option key={"product-option-" + optionIndex + "-" + i} value={x}>
                                {x}
                              </option>
                            ))}
                          </Form.Control>
                        )
                        : (
                          <>
                            <Form.Check
                              inline
                              custom
                              type={"radio"}
                              label={"[Not set]"}
                              name={"product-option-" + optionIndex}
                              checked={!checkSelectedValue(selectedOptions, option.name)}
                              value={""}
                              onChange={(e) => handleInputChanged(option.name, '')}
                              id={"product-option-" + optionIndex + "-none"} />
                            {option.values.map((val, valIndex) => (
                              <Form.Check
                                key={"product-option-" + optionIndex + "-" + valIndex}
                                inline
                                custom
                                type={"radio"}
                                label={val}
                                id={"product-option-" + optionIndex + "-" + valIndex}
                                name={"product-option-" + optionIndex}
                                checked={checkSelectedValue(selectedOptions, option.name) === val}
                                value={val}
                                onChange={(e) => handleInputChanged(option.name, e.target.value)} />
                            ))}
                          </>
                        )}
                    </Col>
                  </Form.Group>
                ))}
              </Form>
            </>
            }
          </>

      }
    </>
  );
};

export default ProductOptions;
