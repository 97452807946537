import axios from 'axios';

const jsonFile = '/data.json';

export async function getCultures() {
  const result = await axios.get(jsonFile, { });
  return result.data['cultures'];
}

export async function getDocumentExamples() {
  const result = await axios.get(jsonFile, { });
  return result.data['examples']['documents'];
}

export async function getProductExamples() {
  const result = await axios.get(jsonFile, { });
  return result.data['examples']['products'];
}
