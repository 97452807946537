import React, { useContext, useEffect, useState } from 'react';
import { Badge, Form } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import { realize } from '../api/dtr-api';
import { AppContext } from '../App';
import { create3DRenderingUrl, createComboWikiLink, createInstructionsUrl, createMcpRenderingUrl } from '../tools/url-helper';

const DtcEntry = ({data, header}) => {
  const { selectedCulture } = useContext(AppContext);

  const [dtrResponse, setDtrResponse] = useState(null);
  const [templateToken, setTemplateToken] = useState('');
  const [wikiLink, setWikiLink] = useState(null);
  const [vertexLink, setVertexLink] = useState(null);
  const [previewUrl, setPreviewUrl] = useState();

  const { quality, designTransformationTokenBase64 } = data;

  useEffect(() => {
    if (quality === 0) {
      return;
    }

    realize(designTransformationTokenBase64, { culture: selectedCulture })
      .then(response => {
        setDtrResponse(response);

        const udsDocumentUrl = createInstructionsUrl(response.realizedDocumentUrl, []);
        const previewUrl = createMcpRenderingUrl(udsDocumentUrl);
        const vertexLink = create3DRenderingUrl(udsDocumentUrl);
        const token = parseTemplateToken(response);

        setTemplateToken(token);
        setWikiLink(createComboWikiLink(token));
        setVertexLink(vertexLink);
        setPreviewUrl(previewUrl);
      })
  }, [selectedCulture, quality, designTransformationTokenBase64]);

  const parseTemplateToken = (dtrResult) => {
    const frontPanel = dtrResult.document.document.panels.filter((p) => p.name === 'Front')[0];

    if (frontPanel) {
      const frontPanelMetadata = dtrResult.document.metadata.documentSources.panels.filter((p) => p.id === frontPanel.id)[0];

      if (frontPanelMetadata && frontPanelMetadata.source === 'TEMPLATE_TOKEN') {
        return frontPanelMetadata.data;
      }
    }

    return '';
  };

  return (
    <div className={"mb-5"}>
      <h5 className={"mt-4"}>{header}</h5>

      <h6 className={"mt-4"}>Quality</h6>
      <Badge variant={quality > 0 ? "success" : "danger"}>{quality}</Badge>

      {quality > 0 && (
        <>
          <h6 className={"mt-4"}>Template Token</h6>
          {templateToken ? templateToken : 'N/A'}
        </>
      )}

      <h6 className={"mt-4"}>DTI</h6>
      <Form.Control as={"textarea"} rows={"5"} style={{width: '100%', opacity: 1, color: 'inherit'}} value={designTransformationTokenBase64} readOnly />

      {quality > 0 && (
        <>
          <h6 className={"mt-4"}>Links</h6>
          <ul>
            <li>
              <a href={wikiLink} target="_blank" rel="noopener noreferrer">
                Core wiki template
              </a>
            </li>
            <li>
              <a href={vertexLink} target="_blank" rel="noopener noreferrer">
                VERTEX Rendering URL
              </a>
            </li>
          </ul>
        </>
      )}

      <h6 className={"mt-4"}>DTC Response</h6>
      <ReactJson src={data} defaultValue={{}} collapsed={true} style={{wordBreak: 'break-all'}} />

      {quality > 0 && (
        <>
          <h6 className={"mt-5"}>DTR Response</h6>
          {!dtrResponse
            ? (
              <>Loading...</>
            )
            : (
              <ReactJson src={dtrResponse} defaultValue={{}} collapsed={true} style={{wordBreak: 'break-all'}} />
            )
          }

          <h6 className={"mt-5"}>Preview</h6>
          {!dtrResponse
            ? (
              <>Loading...</>
            )
            : (
              <img src={previewUrl} style={{ maxWidth: '100%' }} alt={""} />
            )
          }
        </>
      )}
    </div>
  );
};

export default DtcEntry;
