import React, { useState } from 'react';
import CrossSell from './components/cross-sell';
import Footer from './components/footer';

export const AppContext = React.createContext({});

function App() {

  const [documentUrl, setDocumentUrl] = useState('');
  const [productOptionCombination, setProductOptionCombination] = useState(null);
  const [tssResponse, setTssResponse] = useState(null);
  const [dtcResponse, setDtcResponse] = useState(null);
  const [selectedCulture, setSelectedCulture] = useState('en-US');
  const context = {
    documentUrl, setDocumentUrl,
    productOptionCombination, setProductOptionCombination,
    tssResponse, setTssResponse,
    dtcResponse, setDtcResponse,
    selectedCulture, setSelectedCulture
  };

  return (
    <div className={"d-flex flex-column"} style={{ minHeight: "100vh" }}>
      <div className={"flex-grow-1"}>
        <AppContext.Provider value={context}>
          <CrossSell />
        </AppContext.Provider>
      </div>
      <Footer />
    </div>
  );
}

export default App;
