import axios from 'axios';

const host = 'https://catalog.designtransformation.vpsvc.com';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Caller-Id': 'content-catalog-ui'
};

const createRequest = (documentUrl, targetSpecifications) => {
  return {
    _links: {
      document: {
        href: documentUrl
      }
    },
    targetSpecifications: [targetSpecifications.data.targetSpecifications]
  };
};

export async function search(documentUrl, targetSpecifications) {
  const url = `${host}/api/v2/catalogEntries:search`;

  try {
    const request = createRequest(documentUrl, targetSpecifications);
    const result = await axios.post(url, request, { headers });
    return result.data;
  } catch (error) {
    throw error;
  }
}
