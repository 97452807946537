import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { search } from '../api/dtc-api';
import { AppContext } from '../App';
import { getCultures } from '../tools/data-reader';
import DtcEntry from './dtc-entry';

const DtcResult = (props) => {
  const {documentUrl, tssResponse, dtcResponse, setDtcResponse, selectedCulture, setSelectedCulture} = useContext(AppContext);

  const [loading, setLoading] = useState();
  const [cultures, setCultures] = useState([]);

  useEffect(() => {
    getCultures()
      .then(data => {
        setCultures(data);
      })
  }, []);

  useEffect(() => {
    if (documentUrl && tssResponse) {
      setLoading(true);
      search(documentUrl, tssResponse)
        .then(response => {
          setDtcResponse(response);
          setLoading(false);
        })
        .catch(err => {
          console.error('Error calling DTC:', err);
          setDtcResponse(null);
          setLoading(false);
        });
    }
    else {
      setDtcResponse(null);
    }
  }, [documentUrl, tssResponse, setDtcResponse]);

  const handleCultureChange = (cultureCode) => {
    setSelectedCulture(cultureCode);
  };

  return (
    <div className={props.className}>
      {loading
        ? (
          <div className={"text-secondary"}>
            <h6 className={"text-secondary mt-4"}>
              Waiting for service to return the data
            </h6>
            <p>
              Remote service call in progress. Please wait...
            </p>
          </div>
        )
        : dtcResponse
        ?
          <>
            <h6 className={"mt-4"}>Selected Culture</h6>
            <Form.Control as={"select"} value={selectedCulture} onChange={(e) => handleCultureChange(e.target.value)}>
              {cultures.map(culture => <option key={culture.code} value={culture.code}>{culture.name}</option>)}
            </Form.Control>

            {dtcResponse.map((entry, index) => <DtcEntry key={entry.tokenId} data={entry} header={`#${index + 1}`} order={index + 1} />)}
          </>
        : (
            <div className={"text-secondary"}>
              <h6 className={"mt-4"}>
                Document URL or Product-Options Combination not set or invalid
              </h6>
              <p>
                Please, set correct <label htmlFor={"document_url"}><mark>Document URL</mark></label>, select a product from the <label htmlFor={"selected_product"}><mark>Product List</mark></label> and valid <label><mark>Product Options</mark></label>.
              </p>
            </div>
        )}
    </div>
  );
};

export default DtcResult;
