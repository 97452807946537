import React, { useState } from 'react';
import { Button, ButtonGroup, Col, Container, Jumbotron, Row } from 'react-bootstrap';
import DocumentDetails from './document-details';
import DocumentUrl from './document-url';
import DtcResult from './dtc-result';
import ProductOptions from './product-options';
import TssResult from './tss-result';
import classNames from 'classnames';

const CrossSell = () => {
  const [selectedResultsTab, setSelectedResultsTab] = useState('Document');

  return (
    <Container className={'mb-3'}>
      <h1 className={'my-4'}>Cross Sell</h1>

      <Row>
        <Col sm={12} md={12} lg={6}>
          <Jumbotron className={'py-5 bg-white'}>
            <DocumentUrl/>
            <ProductOptions/>
          </Jumbotron>
        </Col>

        <Col sm={12} md={12} lg={6}>
          <div className="d-flex flex-column mt-4">
            <ButtonGroup size={'lg'}>
              {
                ['Document', 'TSS', 'DTC'].map(tab =>
                  <Button key={tab} className={'resultTabsButton'} active={selectedResultsTab === tab}
                          onClick={() => setSelectedResultsTab(tab)}>{tab}</Button>
                )
              }
            </ButtonGroup>
          </div>
          <div className={'px-2'}>
            <DocumentDetails className={classNames(selectedResultsTab !== 'Document' && 'd-none')}/>
            <TssResult className={classNames(selectedResultsTab !== 'TSS' && 'd-none')}/>
            <DtcResult className={classNames(selectedResultsTab !== 'DTC' && 'd-none')}/>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CrossSell;
